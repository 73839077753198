.delete-store-block {
    padding: 20px 100px;
}
.delete-store-header {
    position: relative;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
.delete-store-block .header-img {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.delete-store-header svg {
    margin-right: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.store-form {
    width: 70%;
    margin: 60px auto auto auto;
}
.form-item {
    position: relative;
    margin-bottom: 30px;
}
.form-item input {
    border: 1px solid grey;
    height: 53px;
    width: 100%;
    padding: 16px 30px;
    border-radius: 20px;
    font-size: 14px;
}
.form-item label {
    position: absolute;
    background: #fff;
    top: -13px;
    left: 30px;
    padding: 0 5px;
    color: #7d7d7d;
    font-weight: bold;
}
.select-reason div {
    border: 1px solid #cecece;
    padding: 13px;
    font-size: 16px;
    color: #1b1b1b;
    font-weight: bold;
    border-radius: 15px;
    margin-bottom: 14px;
    cursor: pointer;
}
.select-reason div:hover {
    background: #d4d4d4;
}
.selected-reason {
    background: #d4d4d4 !important;
}
.store-form button {
    width: 50%;
    background: #ea3557;
    font-size: 16px;
    font-weight: bold;
    color: white;
    padding: 16px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 15px;
}
.disabled-btn button {
    pointer-events: none;
    background-color: #7d7d7d;
}


/* ////////////// Media query ////////////// */
@media(max-width: 768px) {
    .delete-store-block {padding: 15px 0;}
    .store-form {width: 100%; padding: 0 15px;}
    .store-form button {width: 100%;}
}
