html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 80px;
}
.about-header button {
  background-color: #EA3557;
  padding: 5px 15px;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}
.about-header button:hover {
  background-color: #f05672;
}
.contact-us a {
  color: blue;

}
.contact-us span {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  padding-right: 10px;
}

.scroll-to-top {
  display: flex;
  justify-content: center;
  align-items: center;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 992px) {
  .about-header {
    padding: 10px;
  }
}